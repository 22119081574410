import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import { MustMatch } from 'src/app/helpers/must-match.validator';
import { PatientService } from 'src/app/services/patient.service';

@Component({
  selector: 'app-inscription-patient',
  templateUrl: './inscription-patient.component.html',
  styleUrls: ['./inscription-patient.component.css'],
})
export class InscriptionPatientComponent implements OnInit {
  registerForm: FormGroup;
  submitted: boolean = false;

  country = 'BE';
  countryCode = '32';
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  TooltipLabel;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom,
  ];
  constructor(private formBuilder: FormBuilder,
    private patientService :PatientService,
    public iziToast: Ng2IzitoastService,
    private router: Router,

  ) {}

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group(
      {
        nom: [
          '',
          [
            Validators.required,
            Validators.maxLength(25),
            Validators.minLength(3),
          ],
        ],
        prenom: [
          '',
          [
            Validators.required,
            Validators.maxLength(25),
            Validators.minLength(3),
          ],
        ],
        email: ['', [Validators.required, Validators.email]],
        tel: ['', Validators.required],
        acceptTerms: [false, Validators.requiredTrue],
      },
     
    );
  }
  get f() {
    return this.registerForm.controls;
  }
  onSubmit() {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    if(!this.registerForm.value.tel){
      return;
    }
    this.registerForm.value.tel =
      this.registerForm.value.tel.internationalNumber;
     
      this.patientService.AddPatient(this.registerForm.value).subscribe(
        (data) => {
          if(data && data["success"]){
            this.iziToast.show({
              message: 'Un lien pour compléter votre inscription a été envoyé à votre adresse e-mail. Veuillez consulter votre dossier de spam si vous ne le trouvez pas dans votre boîte de réception.',
              messageColor: '#21726B',
              progressBarColor: '#21726B',
              imageWidth: 45,
              position: 'topRight',
              timeout: 5000,
              backgroundColor: '#e7f5f5',
              transitionIn: 'flipInX',
              transitionOut: 'flipOutX',
            });
            this.router.navigate(['connexion']);
          }else{
            var msg = "Merci de vérifier vos infromations"
            if(data && !data["success"] && data["errors"] && data["errors"].length > 0){
              msg = data["errors"][0].msg
            }
            this.iziToast.show({
              message:msg,
              messageColor:'#800f2f',
              titleColor:'#800f2f',
              progressBarColor:'#c9184a',
              imageWidth:45,
              position:'topRight',
              timeout:5000,
              backgroundColor:'#ff8fa3',
              transitionIn: 'flipInX',
              transitionOut: 'flipOutX',
            });
          }
          // localStorage.setItem("register","patient")
        },
        (error) => {
          this.iziToast.show({
            message:error.error.errors[0].msg,
            messageColor:'#800f2f',
            titleColor:'#800f2f',
            progressBarColor:'#c9184a',
            imageWidth:45,
            position:'topRight',
            timeout:5000,
            backgroundColor:'#ff8fa3',
            transitionIn: 'flipInX',
            transitionOut: 'flipOutX',
          });
          if(error.error.errors[0].msg == "Email invalide." || 
            error.error.errors[0].msg == "L\'email doit comporter entre 5 et 255 caractères." || 
            error.error.errors[0].msg == "Cet email est déjà utilisé.")
          this.registerForm.patchValue({
            email: '' ,// Update email to an empty string
          });
          return false;
        },
       
      );
  }
  ngAfterViewInit(): void {
    window.scrollTo(0, 0);
  }

   openPDF() {
   // const pdfUrl = '/assets/Medivisto - Conditions générales d’utilisation.pdf';
    //window.open(pdfUrl, '_blank');
   const pdfUrl = `${window.location.origin}/assets/medivisto-conditions-generales-utilisation.pdf`;
    window.open(pdfUrl, '_blank');  
 }
  
}
